@import "../../scss/variables.scss";

.menu-bar {
  width: 100%;
  height: 50px;
  background: $blueColor;
  display: flex;
  justify-content: space-between;
}

.menu-bar-burger {
  height: 50px;
  width: 50px;
  line-height: 50px;
  color: #bfc4ca;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}

.menu-bar-purchases {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.menu-open {
  .menu-bar-burger {
    position: fixed;
    color: $blueColor;
    z-index: 11;
    left: 0;
  }

  &.menu-bar {
    justify-content: flex-end;
  }
}