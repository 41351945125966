@import "../../scss/variables";

.custom-input {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    width: 100%;
    max-width: 300px;

    &.input-error {
        border-left: solid 3px $pinkColor;
    }

    .custom-input-icon {
        display: none;
    }
}

.custom-input.input-with-icon {

    input {
        border-left: none;
    }

    .custom-input-icon {
        display: flex;
    }
}

.custom-input label{
    display: flex;
    align-items: center;
    background: #fff;
    width: 100%;
}

.custom-input input {
    padding: 0 0 0 5px;
    height: 40px;
    width: 100%;
    border: solid 1px #efefef;
    color: #000;
    font-size: 14px;
}

.custom-input-icon {
    padding-right: 10px;
    padding-left: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    border: solid 1px #efefef;
    border-right: none;
}

.with-logo input {
    border-left: none;
}