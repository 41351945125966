.modal-wrapper {
    /*position: fixed;*/
    display: flex;
    width: 100%;
    min-height: 100vh;
    background: rgba(1, 0.2, 0.2, 0.1);
    left: 0;
    top: 0;
    z-index: 1;
    /* opacity: 0.2; */
    justify-content: center;
    align-items: center;
    background: #01172e;
}

.bid-modal {
    width: 400px;
    background: #01172e;
    padding: 10px;
    color: #fff;
    position: relative;
    /*margin: 20px 0;*/

    .bid-card-subtitle {
        text-transform: uppercase;
    }
}

.bid-modal-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.bid-modal-topline {
    position: absolute;
    width: 100%;
    top: 10px;
    left: 0;
    display: flex;
    justify-content: flex-end;
}

.bid-modal-topline .modal-close {
    font-size: 22px;
    cursor: pointer;
    margin-right: 20px;
    z-index: 1;
}

.bid-modal-head {
    margin-top: 20px;
    font-size: 2.8rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
}

.bid-modal-subhead {
    text-align: center;
    margin: 15px 0;

    p {
        margin: 0;
    }
}

.bid-modal-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bid-modal-cover {
    border: solid 1px;
    border-radius: 5px;
    overflow: hidden;
}

.bid-modal-cover img {
    max-width: 100%;
    max-height: 400px;
    height: auto;
    display: block;
}

.bid-modal-info {
    display: flex;
    width: 100%;
    margin-top: 20px;
}

.bid-modal-title {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 10px;
}

.bid-modal-describe {
    border-radius: 20px;
    background: #0c2138;
    padding: 20px 10px 0;
    margin-bottom: 60px;
}

.bid-modal-describe p {
    margin: 10px 0 50px;
    height: 100px;
    max-height: 100px;
    overflow: scroll;
    font-size: 14px;
}

.bid-modal-control {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 160px;
    background: linear-gradient(#01172e00, #01172ecc 30%, #01172e);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    pointer-events: none;
}

.bid-modal-button {
    border: solid 2px #26ffc7;
    background: #01172e;
    color: #26ffc7;
    max-width: 100%;
}

@media screen and (max-width: 480px){
    .bid-modal {
        width: 100%;
        min-height: 100vh;
        display: flex;
        align-items: center;
        box-sizing: border-box;
    }

    .bid-modal-topline {
        top: 20px;
    }

    .bid-modal-topline .modal-close {
        margin-right: 30px;
    }
}