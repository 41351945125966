.purchases-page {
  background: #fff;
}

.purchases-page-content {
  width: 480px;
  max-width: 100%;
}

.purchases-page-section {
  padding-top: 20px;

  h2 {
    margin: 0 0 0 20px;
    font-size: 1.25rem;
    font-weight: 600;
  }

  a {
    text-decoration: none;
    color: #000;

    &.active {
      color: #000;
    }
  }
}

@media screen and (max-width: 480px){
  .purchases-page .content-wrapper {
    width: 100vw;
    max-width: 100vw;
    padding: 0;
    vertical-align: initial;
  }

  .purchases-page.page-wrapper:before {
    height: auto;
  }
}
