.bid-card {
    width: 100%;
    max-width: 400px;
    min-width: 300px;
    min-height: 400px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    border-radius: 10%;
    box-shadow: 0 1px 20px -2px rgba(0, 0,0, 0.2);
    cursor: pointer;
    /*display: flex;
    flex-direction: column-reverse;*/
}

/*
.bid-card-cover {
    position: absolute;
    width: 100%;
    height: 100%;
}

.bid-card-cover img {
    width: 100%;
    height: auto;
}
*/

.bid-card .bid-card-cover {
    z-index: -1;
}

.bid-card .bid-card-logo {
    margin-left: 30px;
}

.bid-card-content {
    width: 100%;
    bottom: 0;
    background: #fff;
    border-top: 2px solid #dad8d7;
}

.bid-card-info {
    display: flex;
    justify-content: space-between;
    margin-top: -30px;
    align-items: center;
}

/*.bid-card-logo {
    width: 60px;
    height: 60px;
    border: 1px solid #dad8d7;
    margin-left: 30px;
}

.bid-card-logo img {
    width: 100%;
    height: 100%;
}

.bid-card-bid {
    height: 30px;
    line-height: 30px;
    background: #ff085c;
    margin-right: 30px;
    padding: 0 10px;
    color: #fff;
    font-weight: 500;
}*/

.bid-card-description {
    padding: 0 30px;
    margin-top: 10px;
}

.bid-card-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

/*.bid-card-header h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}*/

.bid-card-header span {
    font-size: 1rem;
}

.bid-card-subHeader {
    margin: 10px 0;
    text-align: left;
}

/*.bid-card-subHeader h3 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}*/

/*
.bid-card-timer {
    font-family: 'alarm_clockregular';
    font-size: 40px;
    text-align: center;
    padding-top: 8px;
}*/

.bid-card .bid-card-timer {
    margin: 15px 0;
}

.bid-card .bid-card-title {
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*
@media screen and (max-width: 420px){
    .bid-card .bid-card-header {
        flex-direction: column;
        align-items: center;
    }
    
    .bid-card-subHeader {
        text-align: center;
    }

    .bid-card .bid-card-title {
        max-width: 100%;
        white-space: normal;
        overflow: visible;
    }
}*/
