h1 {
    text-align: center;
}

.custom-col {
    display: flex;
    justify-content: center;
}

.bid-inline-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bid-card-wrapper {
    margin-top: 20px;
}