.copyright-wrapper {
  margin-top: 100px;
  font-family: 'DIN Alternate Bold', sans-serif;
  font-size: 8pt;
  color: #FF085C;
  text-align: center;

  a, a:active, a:visited {
    color: #FF085C;
  }

  a {
    text-decoration: none;
  }

  a:hover, a:active {
    text-decoration: underline;
  }
}