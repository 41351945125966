.bid-changer-wrapper {
    display: flex;
    height: 60px;
    align-items: center;
    pointer-events: all;
}

.bid-changer-display {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    padding: 0 20px;
}

.bid-changer-display h4 {
    margin: 0;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 13px;
    color: #9ea7b0;
}

.bid-changer-display span {
    font-size: 18px;
    font-weight: 500;
}

.bid-changer-control {
    font-size: 24px;
    cursor: pointer;
}

.bid-changer-control.deactivated-control {
    cursor: initial;
    color: #9ea7b0;
}