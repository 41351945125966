.form-wrapper {
    form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 100%;
    }

    .recaptcha-wrapper {
        margin-bottom: 15px;
        max-width: 100%;
        display: flex;
        justify-content: center;
    }

    .form-head {
        width: 300px;
        color: #cfd2d6;
        margin-bottom: 20px;
        max-width: 100%;

        p {
            margin: 0;
        }
    }

    .form-control {
        display: flex;
        justify-content: center;
        margin-top: 25px;
        width: 300px;
        max-width: 100%;
    }

    .form-inline-fields {
        display: flex;
        justify-content: space-between;
        width: 300px;
        max-width: 100%;

        & > div {
            margin-right: 0.625rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@media screen and (max-width: 310px) {
    .form-wrapper .recaptcha-wrapper {
        transform: scale(0.8);
    }
}
