.login-button {
    border: solid 2px #26ffc7;
    background: #01172e;
    color: #26ffc7;
    margin: 0 auto 20px;
    max-width: 100%;
}

.no-touch .login-button:hover {
    background: #26ffc7;
    color: #01172e;
}

.login-page {
    background: #01172e;
}

.login-page .login-image {
    max-width: 100%;
    width: auto;
    height: 460px;
    margin-bottom: 30px;
}