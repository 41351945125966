.bid-card-bid {
    height: 30px;
    line-height: 30px;
    background: #ff085c;
    display: inline-block;
    margin-right: 1.875rem;
    padding: 0 10px;
    color: #fff;
    white-space: nowrap;
    font-weight: 500;
}

.bid-card-logo {
    width: 60px;
    min-width: 60px;
    height: 60px;
    border: 1px solid #dad8d7;
    overflow: hidden;
    /*margin-left: 30px;*/
}

.bid-card-logo img {
    width: 100%;
    height: auto;
    min-height: 60px;
    object-fit: cover;
}

.bid-card-cover {
/*    position: absolute;
    width: 100%;
    height: 100%;*/
    max-height: 230px;
    overflow: hidden;
}

.bid-card-cover img {
    width: 100%;
    min-height: 230px;
    height: auto;
    object-fit: cover;
}

.bid-card-timer {
    font-family: 'alarm_clockregular';
    font-size: 2.5rem;
    text-align: center;
}

.bid-card-title {
    margin: 0;
    font-size: 1.125rem;
    font-weight: 600;
}

.bid-card-subtitle {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
}

@media screen and (max-width: 500px){
    .bid-card-title {
/*        overflow: auto;
        white-space: normal;
        max-width: 100%;*/
    }

    .bid-card-title:after {
        /*display: none;*/
    }
}