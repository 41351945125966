.striple-saved {
  width: 350px;

  .form-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .form-control {
    width: 350px;
  }
}