@import '../../scss/variables';

.side-bar {
  position: fixed;
  width: 200px;
  height: 100%;
  background: #fff;
  border-right: 1px solid $grayColor;
  padding-top: 50px;
  left: -200px;
  box-sizing: border-box;
  z-index: 10;
  transition: $defaultTransition;
}

.side-bar.side-bar-open {
  left: 0;
}

.side-bar-content {
  border-top: 1px solid $grayColor;
  display: flex;
  flex-direction: column;

  a {
    display: inline-block;
    min-height: 50px;
    line-height: 50px;
    text-transform: uppercase;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    padding-left: 10px;
    text-decoration: none;

    &.active {
      text-decoration: underline;
    }

    &.sign-out-button {
      border-top: 1px solid $grayColor;
    }
  }
}