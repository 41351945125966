@import "../../scss/variables";

.StripeElement {
  width: 300px;
  max-width: 100%;
  border: solid 1px #efefef;
  background: #fff;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 0 5px;
}

.StripeElement--invalid, .StripeElement--focus {
  border-left: solid 3px $pinkColor;
}

.StripeElement--complete {
  border-left: solid 1px #efefef !important;
}