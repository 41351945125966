.back-button {
    width: 40px;
    height: 40px;
    background: #f9f9fa;
    border-radius: 100%;
    text-align: center;
    line-height: 40px;
    color: #000;
    font-size: 24px;
    box-shadow: 0 1px 20px -2px rgba(0, 0,0, 0.2);
    cursor: pointer;
}