@import "../../scss/variables";

.striple-saved-card {
  width: 120px;
  height: 80px;
  //border: 1px solid $grayColor;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  padding-left: 15px;
  box-shadow: 0 1px 20px -2px rgba(0, 0,0, 0.2);
  font-size: 20px;

  &.button {
    color: $pinkColor;
    align-items: center;
    padding: 0;
    cursor: pointer;
  }

  .card-number {
    color: #ccc;
    font-size: 18px;
  }
}