.page-header {
  width: 100%;
  border-bottom: 1px solid #000;
  padding: 20px 20px 0;
  box-sizing: border-box;
}


.page-header-content {
  display: flex;
  margin-top: 20px;

  h1 {
    font-size: 30px;
    font-weight: 600;
  }
}