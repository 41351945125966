@import '../../scss/variables';

.purchases-card {
  display: flex;
  padding: 30px 1.5rem;
  border-bottom: 1px solid $grayColor;
}

.purchases-card-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: left;
  margin-left: 10px;
  /*white-space: pre-wrap;*/
}

.purchases-card-info {
  display: flex;
  flex-direction: column;
  align-items: left;
  white-space: nowrap;
}

.purchases-timer-info{
 align-items: center;
  font-size: 12px;
  white-space: nowrap;
}
.purchases-card .bid-card-title {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*
@media screen and (max-width: 400px){
  .purchases-card-content {
    flex-direction: column;
  }

  .purchases-card-info {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}*/
