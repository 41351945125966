.page-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    white-space: nowrap;
    text-align: center;
    background: #fafafa;
}

.page-wrapper:before {
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    content: '';
}