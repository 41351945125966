.bid-inline-card {
    width: 100%;
    max-width: 600px;
    min-width: 300px;
    min-height: 90px;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 20px -2px rgba(0, 0,0, 0.2);
    margin-bottom: 10px;
    border: 1px solid #dad8d7;
    padding: 5px 0;
    cursor: pointer;
    box-sizing: border-box;
}

.bid-inline-card .bid-card-title {
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bid-inline-info {
    display: flex;
    width: 100%;
}
/*.bid-inline-card .bid-card-bid {
    height: 30px;
    line-height: 30px;
    background: #ff085c;
    margin-right: 30px;
    padding: 0 10px;
    color: #fff;
    font-weight: 500;
}*/

.bid-inline-card .bid-card-logo {
    margin-left: 1.875rem;
}

.bid-inline-card .bid-card-timer {
    flex-grow: 6;
    text-align: right;
    margin-right: 1.875rem;
}

.bid-inline-card .bid-card-header {
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    min-height: 60px;
    margin-left: 10px;
}

.bid-card-header-second-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

/*
@media screen and (max-width: 500px){
    .bid-inline-card {
        flex-direction: column;
    }

    .bid-inline-card .bid-card-timer {
        margin: 15px 0 10px;
    }
}*/

@media screen and (max-width: 370px){
    .bid-inline-card .bid-card-logo {
        margin-left: 1rem;
    }

    .bid-inline-card .bid-card-timer {
        margin-right: 1rem;
    }
}