@import '../../scss/variables';

.info-wrapper {
  background: $blueColor;
  font-family: 'DIN Alternate Bold', sans-serif;
  font-size: 8pt;
  color: #fff;
  
  p {
    width: 400px;
    max-width: 100%;
    text-align: center;
  }
}