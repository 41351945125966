@import "../../scss/variables";

.striple-product-card {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid $grayColor;
  box-sizing: border-box;
  font-size: 1rem;

  .product-title {
    font-weight: bold;
    font-size: 1.125rem;
  }

  .card-bold {
    font-weight: bold;
  }

  .card-wrapper {
    display: flex;
  }

  .card-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .auction-advisory{
  font-size: .675rem;
  color: $pinkColor;
  }

  .column-info {
    width: 100%;
    margin-left: 10px;
  }

  .card-terms {
    font-size: .875rem;
    color: $darkGrayColor;
  }
}