@import '../../scss/variables';

.nav-bar {
  position: fixed;
  width: 100%;
  height: 50px;
  bottom: 0;
  border-top: 1px solid $grayColor;
  background: #fff;
  display: flex;
  justify-content: center;
  z-index: 8;

  span {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: $grayColor;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    box-sizing: border-box;

    &.active {
      color: #000;
      border-top: 2px solid #000;
      height: calc(100% - 2px);
    }
  }
}