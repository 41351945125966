.bid-page {
    background: #01172e;
}

.bid-page .content-wrapper {
    max-width: 100vw;
    /*min-height: 160vw;*/
    min-height: 100vh;
}

.bid-page-wrapper {
    width: 100%;
    max-width: 500px;
    /*height: auto;*/
    height: 100%;
    color: #fff;
    position: relative;
    /*margin-top: 60px;*/
    margin-bottom: 80px;
}

.bid-page-wrapper .bid-card-cover {
    position: static;
}

/*.bid-page-wrapper .bid-card-timer {*/
   /* color: #fff;*/
/*} */

.bid-page-description {
    margin: 20px 5px 0 5px;
}

.bid-page-info {
    background: #0c2138;
    width: 100%;
    max-width: 480px;
    box-sizing: border-box;
    padding: 20px 20px;
    border-radius: 10%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.bid-page-info p {
    margin-bottom: 60px;
    height: 110px;
    max-height: 150px;
    overflow-y: scroll;
}


.bid-page-header-line {
    display: flex;
}

.bid-page-title {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 2;
}

.bid-page-title-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bid-page-control {
    margin-bottom: 0px;
    position: absolute;
    height: 135px;
    left: 0;
    bottom: 0px;
    width: 100%;
    background: linear-gradient(#01172e00, #01172ecc 30%, #01172e);
    display: flex;
    align-items: flex-end;
    /*align-items: flex;*/
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    pointer-events: none;

    &.center-control {
        justify-content: center;
        margin-bottom: 0px;
    }
}

.bid-page-topline {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 20px;
}

.bid-button {
    width: auto;
    padding: 0 40px;
}

.bid-page-topline .back-button {
    margin-left: 30px;
}

@media screen and (max-width: 480px){
    .bid-page .content-wrapper {
        width: 100vh;
        padding: 0;
        vertical-align: initial;
    }

    .bid-page.page-wrapper:before {
        height: auto;
    }

    .bid-page-wrapper {
        margin-top: 0;
        /* margin-bottom: 20px; */
        margin-bottom: 0px;
        max-width: 100%;
    }

    .bid-page .bid-page-wrapper .bid-card-cover {
        max-height: 100%;
        width:100%;
    }
}

/*
@media screen and (max-width: 420px) {
    .bid-page-title-line {
        flex-direction: column;
    }
}
*/

@media screen and (min-width: 479px){
    .bid-page .bid-page-wrapper .bid-card-cover {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .bid-page-wrapper .bid-card-cover img {
        width: auto;
        max-width: 100%;
    }

    .bid-page-topline {
        top: 10px;
    }

    .bid-page-topline .back-button {
        margin-left: 5px;
    }

    .bid-page-topline .bid-card-bid {
        margin-right: 5px;
    }
}