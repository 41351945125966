@import '../../scss/variables';

.header {
    position: absolute;
    top: 20px;
    left: 20px;
}

.container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $blueColor;
}

.paragraph-container p {
    color: white;
    font-size: 10pt;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    font-family: 'DIN Alternate Bold', sans-serif;
    background: $blueColor;
}