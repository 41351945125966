@import "../../scss/variables";

.page-headline {
  width: 100%;
  height: 50px;
  background: $blueColor;
  box-sizing: border-box;
  padding-top: 15px;

  img {
    width: 150px;
    display: block;
    margin: 0 auto;
  }
}